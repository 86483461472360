import { css } from "@emotion/react";

import { Grid } from "@mui/material";
import Pagination from "@mui/material/Pagination";
import { ThemeProvider, createTheme } from "@mui/material/styles";

import { appColor } from "@/styles/app-colors";

const customTheme = createTheme({
  components: {
    MuiPagination: {
      styleOverrides: {
        root: {
          "& .MuiPaginationItem-root": {
            backgroundColor: `${appColor.Background.grayLight}`, // 選択されたページの背景色
            color: `${appColor.Text.black}`, // 選択されたページのテキスト色
          },
          "& .Mui-selected": {
            backgroundColor: `${appColor.Background.black} !important`, // 選択されたページの背景色
            color: `${appColor.Text.white}`, // 選択されたページのテキスト色
          },
        },
      },
    },
  },
});

type Props = {
  count: number;
  page: number;
  onChange: (event: React.ChangeEvent<unknown>, value: number) => void;
};

export const DateGridPagination = (props: Props) => {
  return (
    <Grid css={iconPaginationBox}>
      <ThemeProvider theme={customTheme}>
        <Pagination count={props.count} page={props.page} onChange={props.onChange} />
      </ThemeProvider>
    </Grid>
  );
};

const iconPaginationBox = css`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  align-self: stretch;
`;
