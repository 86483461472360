import { css } from "@emotion/react";
import React from "react";

import { Grid, useMediaQuery, useTheme } from "@mui/material";
import { GridRenderCellParams, GridValueFormatterParams, GridValueGetterParams } from "@mui/x-data-grid";

import { APIPaths } from "@/apis/api-config";
import { useTodayReservationList } from "@/custom-hooks/dashboard/useTodayReservationList";
import { appColor } from "@/styles/app-colors";
import { appTypo } from "@/styles/app-typography";

import { BaseDataGrid } from "@/components/common/dataGrid/BaseDataGrid";
import { CustomInput } from "@/components/common/input/CustomInput";
import { DateGridPagination } from "@/components/common/pagination/DateGridPagination";
import { SvgIconChevronRight } from "@/svgs";
import dayjs from "dayjs";

const statuses = {
  all: "すべて",
  reserved: "予約済み",
  canceled: "キャンセル済み",
  finished: "終了済み",
} as const;

type Reservation =
  APIPaths["/operator-dash/reservation/list"]["get"]["responses"]["200"]["content"]["application/json"]["objects"][number];

export const getStatus = (reservation: Reservation): keyof typeof statuses | undefined => {
  if (reservation.payment.status === "paid") {
    if (reservation.endsAt <= dayjs().unix()) {
      return "finished";
    } else {
      return "reserved";
    }
  } else if (reservation.payment.status === "refunded") {
    return "canceled";
  }
};

const TodayReservationList = () => {
  const theme = useTheme();
  const isMobile = !useMediaQuery(theme.breakpoints.up("lg"));

  const {
    selectedStatus,
    selectedKeyword,
    handleChangeSelectedStatus,
    handleChangeSearchReservationNumber,
    reservations,
    isFetching,
    sortModel,
    onSortModelChange,
    onRowClick,
    volume,
    currentPage,
    count,
    handlePageChange,
  } = useTodayReservationList();

  return (
    <Grid css={rootStyled}>
      <Grid css={upperContainerStyled}>
        <Grid css={headerStyled}>
          <Grid css={titleStyled}>本日の予約一覧</Grid>
          <Grid css={searchBoxesContainerStyled}>
            <CustomInput
              value={selectedKeyword}
              onChange={handleChangeSearchReservationNumber}
              placeholder="予約番号"
              css={searchBoxStyled}
            />
          </Grid>
        </Grid>
        <Grid css={tabContainerStyled}>
          {(Object.keys(statuses) as (keyof typeof statuses)[]).map((status) => (
            <Grid
              key={status}
              css={tabStyled(status === selectedStatus)}
              onClick={() => handleChangeSelectedStatus(status)}
            >
              <Grid css={tabTextStyled(status === selectedStatus)}>{statuses[status]}</Grid>
            </Grid>
          ))}
        </Grid>
        <Grid css={listStyled}>
          <DateGridPagination count={count} page={currentPage} onChange={handlePageChange} />
          <BaseDataGrid
            rows={reservations ?? []}
            loading={isFetching}
            sortingMode="server"
            sortModel={sortModel}
            onSortModelChange={onSortModelChange}
            onRowClick={onRowClick}
            slots={{
              noRowsOverlay: () => <Grid css={noRowsOverlayStyled}>予約がありません。</Grid>,
            }}
            columns={[
              {
                field: "ticketTitle",
                headerName: "プラン名",
                flex: 2,
                sortable: false,
                valueGetter: (params: GridValueGetterParams<Reservation>) => params.row.ticket.titleObj.ja,
              },
              {
                field: "reservationNumber",
                headerName: "予約番号",
                flex: 1,
                sortable: false,
                minWidth: isMobile ? 64 : 96,
                valueGetter: (params: GridValueGetterParams<Reservation>) =>
                  params.row.id.substring(0, 8).toUpperCase(),
              },
              {
                field: "startsAt",
                headerName: "予約日時",
                flex: 1,
                minWidth: isMobile ? 120 : 136,
                valueFormatter: (params: GridValueFormatterParams<Reservation["startsAt"]>) =>
                  dayjs(params.value * 1000).format("YYYY/MM/DD HH:mm"),
              },
              {
                field: "count",
                headerName: "枚数",
                flex: 0.5,
                minWidth: 88,
                valueFormatter: (params: GridValueFormatterParams<Reservation["startsAt"]>) => `${params.value}枚`,
              },
              {
                field: "checkinCount",
                headerName: "使用済み",
                flex: 0.5,
                minWidth: 88,
                valueFormatter: (params: GridValueFormatterParams<Reservation["startsAt"]>) => `${params.value}枚`,
              },
              {
                field: "status",
                headerName: "ステータス",
                flex: 1,
                sortable: false,
                renderCell: (params: GridRenderCellParams<Reservation>) => {
                  switch (getStatus(params.row)) {
                    case "reserved":
                      return (
                        <Grid css={statusBox(appColor.Border.lightGreen, appColor.Background.ultraLightGreen)}>
                          <Grid css={statusFont(appColor.Text.mossGreen)}>予約済み</Grid>
                        </Grid>
                      );
                    case "canceled":
                      return (
                        <Grid css={statusBox(appColor.Border.red, appColor.Background.pink)}>
                          <Grid css={statusFont(appColor.Text.lightRed)}>キャンセル</Grid>
                        </Grid>
                      );
                    case "finished":
                      return (
                        <Grid css={statusBox(appColor.Border.darkGray, appColor.Text.darkGray)}>
                          <Grid css={statusFont(appColor.Text.white)}>終了</Grid>
                        </Grid>
                      );
                  }
                },
              },
              {
                field: "actions",
                headerName: "",
                sortable: false,
                flex: 0.2,
                renderCell: (params) => (
                  <Grid css={iconMenuStyled}>
                    <SvgIconChevronRight />
                  </Grid>
                ),
              },
            ]}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default React.memo(TodayReservationList);

const rootStyled = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  align-self: stretch;
`;

const upperContainerStyled = css`
  display: flex;
  flex-direction: column;
  padding: 24px 0px 16px 0px;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
`;

const headerStyled = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
`;

const titleStyled = css`
  color: ${appColor.Text.primary};
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 115%;
`;

const searchBoxesContainerStyled = css`
  display: flex;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
`;

const searchBoxStyled = css`
  display: flex;
  width: 160px;
  padding: 4px 8px;
  align-items: center;
  gap: 16px;
`;

const tabContainerStyled = css`
  display: flex;
  align-items: flex-start;
  align-self: stretch;
  gap: 28px;
  border-bottom: 1px solid ${appColor.Border.grayUltraLight};
`;

const tabStyled = (isSelected: boolean) => css`
  display: flex;
  padding: 8px 0px;
  justify-content: center;
  align-items: center;
  border-bottom: ${isSelected ? `2px solid ${appColor.Border.primary}` : "none"};
`;

const tabTextStyled = (isSelected: boolean) => css`
  color: ${isSelected ? appColor.Text.primary : appColor.Text.gray};
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%; /* 14px */
`;

const listStyled = css`
  width: 100%;
  overflow: auto;
`;

const statusBox = (borderColor: string, backColor: string) => css`
  display: flex;
  padding: 4px 8px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border: 1px solid ${borderColor};
  background: ${backColor};
`;

const statusFont = (color: string) => css`
  color: ${color};
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%; /* 12px */
`;

const iconMenuStyled = css`
  display: flex;
  width: 28px;
  height: 28px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  cursor: pointer;
`;

const noRowsOverlayStyled = css`
  display: flex;
  color: ${appColor.Text.darkGray};
  font-family: ${appTypo.font.kintoSans};
  justify-content: center;
  align-items: center;
  height: 100%;
`;
