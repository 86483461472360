import { css } from "@emotion/react";

import { TextField, TextFieldProps } from "@mui/material";

import { appColor } from "@/styles/app-colors";
import { appTypo } from "@/styles/app-typography";

type Props = TextFieldProps & {
  label?: React.ReactNode;
};

export const CustomInput: React.FC<Props> = ({ label, ...rest }) => {
  return <TextField {...rest} css={inputStyled} className={rest.className} />;
};

const inputStyled = css`
  width: 352px;
  & .MuiOutlinedInput-root {
    display: flex;
    height: 100%;
    color: ${appColor.Text.primary};
    font-family: ${appTypo.font.kintoSans};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5; /* 19.6px */
    & ::placeholder {
      color: ${appColor.Text.gray};
      opacity: 1;
    }
  }
  & .MuiOutlinedInput-input {
    color: ${appColor.Text.primary};
    box-sizing: content-box;
    &.Mui-disabled {
      background: ${appColor.Background.lightBluish};
      -webkit-text-fill-color: ${appColor.Text.primary};
    }
  }
  & .MuiOutlinedInput-root .MuiOutlinedInput-input {
    padding: 4px 8px;
  }
  & .MuiInputBase-multiline {
    padding: 8px;
    overflow-y: hidden;
    align-items: flex-start;
    & .MuiInputBase-input {
      padding: 0px;
      max-height: 100%;
      overflow: auto !important;
    }
  }
`;
