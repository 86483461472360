import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";

import { GridEventListener, GridSortModel } from "@mui/x-data-grid";

import { useApiConnector } from "@/custom-hooks/apis-common/use-api-connector";

import { reservationDetailPath, reservationListPath } from "@/constants/app-paths";
import { selectedSpotIdAtom } from "@/global-states/common-atoms";
import { SortByOrderType } from "@/types/common-types";
import { useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import { useAtomValue } from "jotai";

const ITEMS_PER_PAGE = 10;

const statuses = {
  all: "すべて",
  reserved: "予約済み",
  canceled: "キャンセル済み",
  finished: "終了済み",
} as const;

export const useTodayReservationList = () => {
  const router = useRouter();
  const atomSpotId = useAtomValue(selectedSpotIdAtom);
  const { page, status, sortBy, keyword } = router.query as {
    status: keyof typeof statuses;
    page: string;
    sortBy: string;
    keyword: string;
  };
  const sortByField = sortBy?.split("-")[0];
  const sortByOrder = sortBy?.split("-")[1] as SortByOrderType;
  const [currentPage, setCurrentPage] = useState(isNaN(Number(page)) ? 1 : Number(page));
  const [selectedStatus, setSelectedStatus] = useState<keyof typeof statuses>(status ?? "reserved");
  const [selectedKeyword, setselectedKeyword] = useState(keyword);
  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: sortByField ?? "createdAt",
      sort: sortByOrder ?? "desc",
    },
  ]);

  useEffect(() => {
    router.push({
      query: {
        status: selectedStatus,
        page: currentPage,
        sortBy: sortModel.length !== 0 ? `${sortModel[0].field}-${sortModel[0].sort}` : "startsAt-asc",
        keyword: selectedKeyword,
      },
    });
  }, [selectedStatus, currentPage, sortModel, selectedKeyword]);

  const { operatorReservationApi } = useApiConnector();

  const handleChangeSelectedStatus = (newValue: keyof typeof statuses) => {
    setSelectedStatus(newValue);
    setCurrentPage(1);
  };

  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setCurrentPage(value);
  };

  const onSortModelChange = (newSortModel: GridSortModel) => setSortModel(newSortModel);

  const handleChangeSearchReservationNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
    setselectedKeyword(e.target.value);
  };

  const { data: reservations, isFetching } = useQuery({
    queryKey: [
      "operatorReservationApi.getReservationList",
      {
        spotId: atomSpotId,
        offset: (currentPage - 1) * ITEMS_PER_PAGE,
        limit: ITEMS_PER_PAGE,
        sortBy: sortModel.length !== 0 ? `${sortModel[0].field}-${sortModel[0].sort}` : "startsAt-asc",
        paymentStatus:
          selectedStatus === "reserved" || selectedStatus === "finished"
            ? "paid"
            : selectedStatus === "canceled"
            ? "refunded"
            : undefined,
        minStartAt: dayjs().startOf("day").unix().toString(),
        maxStartAt: dayjs().endOf("day").unix().toString(),
        minEndAt: selectedStatus === "reserved" ? dayjs().set("second", 0).unix().toString() : undefined,
        maxEndAt: selectedStatus === "finished" ? dayjs().set("second", 0).unix().toString() : undefined,
        keyword: selectedKeyword,
      },
    ],
    queryFn: () =>
      operatorReservationApi.getReservationList({
        spotId: atomSpotId,
        offset: (currentPage - 1) * ITEMS_PER_PAGE,
        limit: ITEMS_PER_PAGE,
        sortBy: sortModel.length !== 0 ? `${sortModel[0].field}-${sortModel[0].sort}` : "startsAt-asc",
        paymentStatus:
          selectedStatus === "reserved" || selectedStatus === "finished"
            ? "paid"
            : selectedStatus === "canceled"
            ? "refunded"
            : undefined,
        minStartAt: dayjs().startOf("day").unix().toString(),
        maxStartAt: dayjs().endOf("day").unix().toString(),
        minEndAt: selectedStatus === "reserved" ? dayjs().set("second", 0).unix().toString() : undefined,
        maxEndAt: selectedStatus === "finished" ? dayjs().set("second", 0).unix().toString() : undefined,
        keyword: selectedKeyword,
      }),
  });

  const onRowClick: GridEventListener<"rowClick"> = (params) => {
    router.push(reservationDetailPath(params.row.id));
  };
  const pageCount = reservations?.paging.total ? Math.ceil(reservations?.paging.total / ITEMS_PER_PAGE) : 0;

  useEffect(() => {
    if ((!isNaN(Number(page)) && pageCount && Number(page) > pageCount) || Number(page) < 0) {
      setCurrentPage(1);
    }
  }, [pageCount]);

  return {
    selectedStatus,
    selectedKeyword,
    handleChangeSelectedStatus,
    handleChangeSearchReservationNumber,
    reservations: reservations?.objects,
    isFetching,
    sortModel,
    onSortModelChange,
    onRowClick,
    volume: ITEMS_PER_PAGE,
    currentPage,
    count: pageCount,
    handlePageChange,
  };
};
